export const formatAmount = (d, curr = 'GBP') => {
  // Always use UK locale for formatting numbers
  const locale = 'en-GB';

  if (!d || isNaN(d)) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: curr,
    }).format(0);
  }

  const amount2dp = parseFloat(d).toFixed(2);
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: curr,
  }).format(amount2dp);
}
