<template>
  <div v-if="invoice">
    <huboo-page>
      <v-container>
        <v-row class="align-center">
          <v-col cols="3">
            <huboo-page-heading :heading="pageHeading"></huboo-page-heading>
          </v-col>
          <v-col cols="3">
            <v-badge bordered overlap offset-x="-5" :color="statusBadgeColour">
              <span class="text-h5">
                {{ $t('invoiceSummary.status') }} {{ invoice.attributes.status }}
              </span>
            </v-badge>
          </v-col>
          <v-col cols="3">
            <v-btn
              id="sync-invoice-button"
              :disabled="syncButtonDisabled"
              :loading="syncingInvoice"
              color="primary"
              @click="onSync"
            >
              {{ $t('invoiceSummary.syncButtonTitle') }}
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              id="delete-invoice-button"
              :disabled="deleteButtonDisabled"
              :loading="deletingInvoice"
              color="error"
              @click="onDelete"
            >
              {{ $t('invoiceSummary.deleteButtonTitle') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </huboo-page>
    <v-divider></v-divider>
    <v-card class="ma-4">
      <v-card-title>{{ $t('invoiceSummary.invoiceInfo.title') }}</v-card-title>

      <v-row>
        <v-col cols="4">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.currency')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>{{ invoice.attributes.currency }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.totalExVat')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>
                  {{ formatAmount(invoice.attributes.subTotal, invoice.attributes.currency) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.vat')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>
                  {{ formatAmount(invoice.attributes.vatTotal, invoice.attributes.currency) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.totalIncldVat')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>
                  {{ formatAmount(invoice.attributes.grandTotal, invoice.attributes.currency) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="4">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.invoiceFrom')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>{{ formatDate(invoice.attributes.dateFrom) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.invoiceTo')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>{{ formatDate(invoice.attributes.dateTo) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.postingDate')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>{{
                  formatDate(invoice.attributes.invoicedAt)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.dueDate')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>{{ formatDate(invoice.attributes.dueDate) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="4">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.hubooBox')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>{{ invoice.attributes.clientId }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.billingType')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>{{ invoice.attributes.billingType }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t('invoiceSummary.invoiceInfo.billingPreferences')
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <v-list-item-title>{{ invoice.attributes.billingPreference }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="ma-4" id="invoice-attachments">
      <v-card-title>{{ $t('invoiceSummary.invoiceAttachments.title') }}</v-card-title>
      <v-card-subtitle v-if="attachments.length === 0">{{
        $t('invoiceSummary.invoiceAttachments.noAttachments')
      }}</v-card-subtitle>
      <v-list v-else>
        <v-list-item-group>
          <v-list-item
            v-for="attachment in attachments"
            :key="attachment.id"
            @click='downloadAttachmentById(attachment.id)'
            :disabled='attachmentStatusDisplay(attachment.attributes.status, attachment.attributes.path).disabled'
            >
            <v-list-item-icon>
              <v-icon color="indigo">attachment</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span>
                  {{ attachmentStatusDisplay(attachment.attributes.status, attachment.attributes.path).title}}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

    <v-card class="ma-4">
      <v-card-title>{{ $t('invoiceSummary.billingItems.title') }}</v-card-title>
      <invoice-items :propItem="invoice"></invoice-items>
    </v-card>

    <v-card class="ma-4" id="invoice-responses">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('invoiceSummary.xeroResponses.title') }}
          </v-expansion-panel-header>
          <template v-if="xeroResponses">
            <v-expansion-panel-content v-for="(response, index) in xeroResponses" :key="index">
              {{ response }}
            </v-expansion-panel-content>
          </template>
          <v-expansion-panel-content v-else>
            {{ $t('invoiceSummary.xeroResponses.noResponses') }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <HubConfirmationModal
      :value="confirmationModal"
      :cancelText="$t('billing.defaultConfirmationModal.cancel')"
      :confirmText="$t('billing.defaultConfirmationModal.confirm')"
      qaName="default-hub-confirmation"
      @cancel="modalCancel"
      @confirm="modalConfirm"
      danger
    >
      <template #title>
        {{ $t('billing.defaultConfirmationModal.title') }}
      </template>
      <template>
        {{ $t('billing.defaultConfirmationModal.message') }}
      </template>
    </HubConfirmationModal>
  </div>
  <div v-else>
    Loading...
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { format } from 'date-fns'
import InvoiceItems from './InvoiceItems.vue'
import ClientMixin from '@/mixins/client'
import { InvoiceAttachmentStatus, InvoiceStatus } from '@/modules/Invoice/invoice.store'
import { formatAmount } from '@/modules/Invoice/helpers'
import i18n from '@/boot/i18n'

export default {
  name: 'InvoiceSummary',
  mixins: [ClientMixin],
  components: {
    'invoice-items': InvoiceItems,
  },
  data: () => ({
    syncingInvoice: false,
    deletingInvoice: false,
    confirmationModal: false,
    downloadingAttachmentId: null,
  }),
  computed: {
    ...mapGetters('invoice', ['getSelectedInvoice']),
    ...mapGetters('core', ['getIsAdmin']),
    isAdmin() {
      return this.getIsAdmin
    },
    pageHeading() {
      return i18n.t('invoiceSummary.pageHeading') + (this.invoice?.id || '')
    },
    invoice() {
      return this.getSelectedInvoice
    },
    syncButtonDisabled() {
      return (
        !this.isAdmin ||
        this.syncingInvoice ||
        this.invoice?.attributes?.status === InvoiceStatus.SYNCED ||
        this.invoice?.attributes?.status === InvoiceStatus.SYNCING
      )
    },
    deleteButtonDisabled() {
      return (
        !this.isAdmin ||
        this.deletingInvoice ||
        this.invoice?.attributes?.status === InvoiceStatus.SYNCED ||
        this.invoice?.attributes?.status === InvoiceStatus.SYNCING
      )
    },
    statusBadgeColour() {
      switch (this.invoice?.attributes?.status) {
        case InvoiceStatus.FAILED:
          return 'red'
        case InvoiceStatus.SYNCED:
          return 'green'
        case InvoiceStatus.SYNCING:
          return 'yellow'
        case InvoiceStatus.DRAFT:
          return 'blue'
        default:
          return 'grey'
      }
    },
    attachments() {
      return this.invoice?.relationships?.invoiceAttachment || []
    },
    xeroResponses() {
      if (!this.invoice.attributes.response || this.invoice.attributes.response.length === 0) {
        return null
      }
      return this.invoice.attributes.response
    },
  },
  async created() {
    const invoiceId = this.$route.params.invoiceId
    if (invoiceId) {
      const invoice = await this.fetchSingleInvoice(invoiceId)
      if (!invoice) {
        this.$router.push({
          name: 'Invoice',
        })
      }
    }
  },
  methods: {
    ...mapActions('invoice', ['syncInvoice', 'fetchSingleInvoice', 'delete', 'downloadAttachment']),
    async onSync() {
      if (!this.invoice?.id || this.syncButtonDisabled) return

      this.syncingInvoice = true
      try {
        await this.syncInvoice({ id: this.invoice.id })
      } catch (e) {
        console.error(e)
      } finally {
        this.syncingInvoice = false
      }
    },
    formatDate(date) {
      return format(new Date(date), 'dd-MMM-yyyy')
    },
    formatAmount(amount, currency) {
      return formatAmount(amount, currency)
    },
    attachmentStatusDisplay(status, path) {
      switch (status) {
        case InvoiceAttachmentStatus.REQUESTED:
        case InvoiceAttachmentStatus.RUNNING:
        case InvoiceAttachmentStatus.SUCCEEDED:
            return {title: '(processing_attachment)', disabled: true};
        case InvoiceAttachmentStatus.NO_DATA:
        case InvoiceAttachmentStatus.EXPORT_FAILED:
        case InvoiceAttachmentStatus.UPLOAD_FAILED:
            return {title: `(${status})`, disabled: true};
        case InvoiceAttachmentStatus.UPLOADED:
            return {title: path, disabled: false};
        default:
            return {title: '(unknown)', disabled: true};
      }
    },
    onDelete() {
      if (!this.invoice?.id || this.deleteButtonDisabled) return
      this.confirmationModal = true
    },
    modalCancel() {
      this.confirmationModal = false
    },
    async modalConfirm() {
      this.deletingInvoice = true
      // closes the confirmation modal to avoid double click on confirm button
      this.confirmationModal = false
      try {
        await this.delete(this.invoice.id)
        // navigate back to invoice.vue
        await this.$router.push({
          name: 'Invoice',
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.deletingInvoice = false
      }
    },
    async downloadAttachmentById(attachmentId) {
      if (!this.invoice?.id) return;
      this.downloadingAttachmentId = attachmentId;
      try {
        const attachment = this.attachments.find(att => att.id === attachmentId);
        const filename = attachment?.attributes?.path || `attachment-${attachmentId}.csv`;

        const blobData = await this.downloadAttachment({
          invoiceId: this.invoice.id,
          attachmentId: attachmentId
        });

        if (!blobData) {
          throw new Error('No data received from download request');
        }

        // Create a blob URL
        const url = window.URL.createObjectURL(
          new Blob([blobData], { type: 'text/csv' })
        );

        // Create download link
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // Clean up the link and object URL after a timeout (500ms is usually sufficient)
        // This gives the browser time to start the download before clean up and avoids interrupting the download.
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 500);

      } catch (error) {
        console.error('Error downloading attachment:', error);
      } finally {
        this.downloadingAttachmentId = null;
      }
    }
  },
}
</script>

<style lang="scss" scoped></style>
