<template>
  <div class="pa-3">
    <v-data-table
      id="invoiceItemsTable"
      :headers="headers"
      :items="items"
      :items-per-page="options.itemsPerPage"
      class="elevation-1 ma-4"
      @click:row="handleRowClick"
    ></v-data-table>
  </div>
</template>

<script>
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import { formatAmount } from '@/modules/Invoice/helpers'

export default {
  name: 'InvoiceItems',
  mixins: [ClientMixin],
  components: {},
  props: {
    propItem: Object,
  },
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 10,
    },
  }),
  computed: {
    invoiceItemHeaders() {
      return [
        {
          text: title(this.$t('invoiceItems.fields.billingDate')),
          sortable: false,
          value: 'billingDate',
        },
        {
          text: title(this.$t('invoiceItems.fields.type')),
          sortable: false,
          value: 'attributes.generalLedgerCodeId',
        },
        {
          text: title(this.$t('invoiceItems.fields.description')),
          sortable: false,
          value: 'attributes.description',
        },
        {
          text: title(this.$t('invoiceItems.fields.currency')),
          sortable: false,
          value: 'currency',
        },
        {
          text: title(this.$t('invoiceItems.fields.total')),
          sortable: false,
          value: 'attributes.invoiceItemTotal',
        },
        {
          text: title(this.$t('invoiceItems.fields.synced')),
          sortable: false,
          value: 'synced',
        },
      ]
    },
    headers() {
      return this.invoiceItemHeaders
    },
    items() {
      return this.invoiceItems.map(item => {
        return {
          ...item,
          billingDate: this.formatDate(this.propItem.attributes.dateTo),
          synced: !!this.propItem.attributes.externalGuid,
          currency: this.propItem.attributes.currency,
          attributes: {
            ...item.attributes,
            invoiceItemTotal: formatAmount(item.attributes.total, this.propItem.attributes.currency),
            invoiceItemVat: formatAmount(item.attributes.vat, this.propItem.attributes.currency),
          },
        }
      })
    },
    invoiceItems() {
      const lists = this.propItem.relationships.invoiceItem
      if (Array.isArray(lists))
        return lists.map(l => ({
          ...l,
        }))
      else return []
    },
  },
  watch: {
    options: {
      handler() {},
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {
    handleRowClick(event, row) {
      console.log('Invoice Item selected:', row)
      console.log('Event:', event)
    },
  },
}
</script>

<style lang="scss" scoped></style>
